
import { createStore } from "vuex";
import createPersistedState from 'vuex-persistedstate'
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import axios from "axios"



export default createStore({
  plugins:[createPersistedState()],
  state: {
    hideConfigButton: false,
    redirect_url:null,
    loggedInUser:{
        refresh: "",
        access: "",
        first_name: "",
        last_name: "",
        username: "",
        user_id:null,
        groups:null
    },
    baseUrl:"/intranet",
    currentPost:{},
    isAuthenticated:false,
    posts:[],
    todaysPosts:[],
    postLogs:[],
    products:[],
    contacts:[],
    policies:[],
    banners:[],
    categories:[],
    feedbackOptions:[],
    postAttachments:{},
    holdingsInfo :{},
    isPinned: true,
    showConfig: false,
    isTransparent: "",
    isRTL: false,
    color: "",
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    currentPolicy:{},
    dates:{
      startDate:"",
      endDate:""
    },
    navbarFixed:
      "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
    absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
    bootstrap,
  },
  mutations: {
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    saveCurrentUser(state,payload){
      state.loggedInUser={
        ...payload
      }
    },
    saveDate(state,payload){
      state.dates = {
        ...payload
      }
    },
    async createPostLog(state,payload){
      try{
        const response = await axios.post(
          "intranet/posts/add_post_log/",payload.data,{
            headers:{
              Authorization:`Bearer ${payload.access}`
            }
          }
        )
        if(response.status==201 || response.status==200){
          console.log("Successfully added Post")
        }
      }catch(error){
        console.log(error)
      }
    },

    setCurrentPost(state,obj) {
      // const filterResult = state.posts.find(a => a.pk == obj.id)
      // if(filterResult===undefined){
      //   state.currentPost = obj.obj
      // }else{
      //   state.currentPost=filterResult
      // }
      state.currentPost = obj.id
    },
    setCurrentPolicy(state,obj){
      state.currentPolicy=obj.policy
    },
    changeAuthenticationStatus(state,payload){
      state.isAuthenticated=payload
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");
      if (sidenav_show.classList.contains("g-sidenav-hidden")) {
        sidenav_show.classList.remove("g-sidenav-hidden");
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    sidebarType(state, payload) {
      state.isTransparent = payload;
    },
    cardBackground(state, payload) {
      state.color = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    toggleEveryDisplay(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    toggleHideConfig(state) {
      state.hideConfigButton = !state.hideConfigButton;
    },
    async fetchPosts(state,payload){
      try{
        const response = await axios.get("/intranet/posts/fetch_posts/",{
          headers:{
            Authorization:`Bearer ${payload.access}`
          }
        })
        if(response.status==200){
          state.posts=response.data
        }
      } catch(error){
        console.log({error})
      }
    },
    async fetchPostLogs(state,payload){
      try{
        const response = await axios.get("/intranet/posts/fetch_post_logs/",{
        headers:{
          Authorization:`Bearer ${payload.access}`
        }
      })
        if(response.status === 200){
          state.postLogs = response.data
        }
      }catch(error){
        console.log(error);
      }
    },
    async fetchContacts(state,payload){
      try{
        const response = await axios.get("/intranet/posts/get_contacts/",{         
         headers:{
          Authorization:`Bearer ${payload.access}`
        }});
        if(response.status === 200){
          state.contacts = response.data
          console.log({"products":state.products })
        }
      }catch(error){
        console.log(error);
      }
    },
    async fetchProducts(state,payload){
      try{
        const response = await axios.get("/intranet/products/get_products/",{
          headers:{
            Authorization:`Bearer ${payload.access}`
          }});
        if(response.status === 200){
          state.products = response.data
          console.log({state})
        }
      }catch(error){
        console.log(error);
      }
    },
    async fetchPolicies(state,payload){
      try{
        const response = await axios.get("/intranet/posts/fetch_policies/",{
        headers:{
          Authorization:`Bearer ${payload.access}`
        }})
        if(response.status === 200){
          state.policies = response.data
        }
      }catch(error){
        console.log(error);
      }
    },
    async getBanners(state,payload){
      try{
      const response = await axios.get("/intranet/posts/fetch_ad_imgs/",
      {
        headers:{
          Authorization: `Bearer ${payload.access}`
        }
      })
      if(response.status == 200){
        console.log({x:response.data})
        state.banners = response.data.bannersList.filter(b=>b.active=="Active");
      }
    }catch(e){
      console.log({e})
    }
    }
    ,
    async fetchCategories(state,payload){
      try{
        const response = await axios.get("/intranet/posts/fetch_categories/",
        {         
         headers:{
          Authorization:`Bearer ${payload.access}`
        }});
        if(response.status === 200){
          state.categories = response.data
        }
      }catch(error){
        console.log(error);
      }
    },
    async fetchFeedBackCategories(state,payload){
      try{
          const response = await axios.get("intranet/posts/fetch_feedback_options/",    {         
          headers:{
           Authorization:`Bearer ${payload.access}`
         }})
        if(response.status==200){
          const options = response.data["options"]
          state.feedbackOptions = options
        }
      }catch(error){
        if(error.name==="AxiosError"){
          if(error.response.status === 401){
            // try to do some logging out stuff
            // will figure it out later
          }
        }
      }
    },
    async postFeedBack(state,payload){
      try{
        console.log({payload})
        const response = await axios.post(
          "intranet/posts/post_feedback/",
          payload.data,
          {
            headers:{
              Authorization:`Bearer ${payload.access}`
            }
          }
        )
        if(response.status == 201){
          console.log("Successfully added Feedback")
        }
        }catch(error){
        console.log(error)
          }
    },
    // get_latest_post/
    async fetchLatestPosts(state,payload){
      try{
        const response = await axios.get(
          "intranet/posts/get_latest_post/",{
            headers:{
              Authorization:`Bearer ${payload.access}`
            }
      }
        )
        if(response.status == 200){
          state.todaysPosts = response.data
        }
      }catch(error){
        console.log(error)
      }
    },
    async fetchHoldingsInfo(state,payload){
      try{
        const response = await axios.get(
          "intranet/posts/fetchHoldings_details/",
          {
            headers:{
              Authorization:`Bearer ${payload.access}`
            }
          }
        )
        if(response.status == 200){
          const unformattedData = response.data.length > 0 ? response.data[0]: {}
          if(Object.keys(unformattedData).length > 0){
            const newObject = {
              ...unformattedData,
              values:  unformattedData["values"].split(',').map(str=>str.replace('\n','')),
              principles: unformattedData["principles"].split(',').map(str=>str.replace('\n',''))}
              state.holdingsInfo = newObject
          }
        }
      }catch(error){
        console.log(error)
      }
    },
    async fetchPostAdditionalAttachments(state,payload){
      try{
        const {post_id,access} = payload
        const response = await axios.get("intranet/posts/get_more_attachments/?post_id="+post_id,{
          
            headers:{
              Authorization:`Bearer ${access}`
            }
          }
        );
        if(response.status==200){
          const responseData = response.data
          if(typeof response === "object" && Array.isArray(response)){
            const attachments = responseData
            console.log({attachments})
            state.postAttachments = {
              post_id:attachments
            }
          }
        }
      }catch(error){
        // we need to add dialogs for the attachments and stuff
        console.log(error)
      }
     },
    
    signOut(state){
      state.loggedInUser={
        refresh: "",
        access: "",
        first_name: "",
        last_name: "",
        username: "",
        user_id:null
    };
    state.isAuthenticated = false
    }

  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    },
    setCardBackground({ commit }, payload) {
      commit("cardBackground", payload);
    },
    getBannersAction({ commit },payload){
      commit("getBanners",payload)
    },
    fetchPostsAction({commit},payload){
      commit("fetchPosts",payload)
    },
    fetchPostLogsAction({commit},payload){
      commit("fetchPostLogs",payload)
    },
    fetchLatestPostsAction({commit},payload){
      commit("fetchLatestPosts",payload)
    },
    fetchContactsAction({commit},payload){
      commit("fetchContacts",payload)
    },
    fetchProductsAction({commit},payload){
      commit("fetchProducts",payload)
    },
    fetchCategoriesAction({commit},payload){
      commit("fetchCategories",payload)
    },
    fetchFeedBackCategoriesAction({commit},payload){
      commit("fetchFeedBackCategories",payload)
    },
    fetchPoliciesAction({commit},payload){
      commit("fetchPolicies",payload)
    },
    fetchHoldingsInfoAction({commit},payload){
      commit("fetchHoldingsInfo",payload)
    },
    fetchPostAdditionalAttachmentsAction({commit},payload){
      commit("fetchPostAdditionalAttachments",payload)
    },
    setNewPost({commit},payload){
      commit("setCurrentPost",payload)
    },
    setNewPolicy({commit},payload){
      commit("setCurrentPolicy",payload)
    },
    saveNewPostLog({commit},payload){
      commit("createPostLog",payload)
    },
    saveNewFeedBack({commit},payload){
      commit("postFeedBack",payload)
    },
    setDateArchives({commit},payload){
      commit("saveDate",payload)
    }
    // getAttachmentForCurrentPost({commit},payload){
    //   commit("fetchPostAdditionalAttachments",payload)
    // }
  },
  getters: {
    currentUser(state){
      return state.loggedInUser;
    },
    isAuthenticated(state){
      return state.isAuthenticated;
    },
    currentPosts(state){
      return state.posts;
    },
    currentJumpPosts(state){
      return state.posts.filter(post=>post.category_id && post.category_id.type==="JUMP");
    },
    currentCircularPosts(state){
      return state.posts.filter(post=>post.category_id && post.category_id.type!=="JUMP")
    },
    getCurrentPost(state){
      console.log({ "state.currentPost": state.currentPost })
      return state.currentPost;
    },
    getPostLogs(state){
      return state.postLogs;
    },
    getBaseUrl(state){
      return state.baseUrl;
    },
    getViewReportStatus(state){
        const user = state.loggedInUser;
        console.log({user})
        if(user.groups){
          return user.groups.includes("View_Reports")
        }
        return false
    },
    getCurrentContacts(state){
      return state.contacts;
    },
    getCurrentProducts(state){
      return state.products;
    },
    getCurrentPolicies(state){
      return state.policies
    },
    getPostCategories(state){
      return state.categories
    },
    getFeedBackCategories(state){
      return state.feedbackOptions
    },
    getHoldingsInfo(state){
      return state.holdingsInfo
    },
    getTodaysPosts(state){
      return state.todaysPosts
    },
    getPostAttachments(state){
      return state.postAttachments
    },
    getBanners(state){
      return state.banners
    },
    getCurrentPolicy(state){
      return state.currentPolicy
    },
    getDates(state){
      return state.dates
    }
  },
});
