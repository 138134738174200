<template >
  <!-- <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow"
          btn-background="bg-gradient-success"
          :dark-mode="true"
        />
      </div>
    </div>
  </div> -->
  <soft-alert v-if="loginSuccess">
    Successfully Logged in!
 </soft-alert>
 <soft-alert v-if="loginFailure" color="danger">
    Failed to login check username or password.
 </soft-alert>
 <soft-alert v-if="timeoutOrError" color="warning">
    Failed to login due to some technical challenges.Try again later
 </soft-alert>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div class="mt-8 card card-plain">
                <img :src="logo" class="navbar-brand-img" alt="main_logo"/>
                <div class="pb-0 card-header text-center" style="background-color: transparent;">
                  <!-- <h3 class="font-weight-bolder text-primary text-gradient">
                    Welcome 
                  </h3> -->
                  <p v-if="false" class="mb-0">Enter your username and password to sign in</p>
                </div>
                <div class="card-body">
                  <form role="form" class="text-start">
                    <label>Username</label>
                    <div class="form-group">
                        <div>
                          <input
                            id="username"
                            type="username"
                            class="form-control"
                            name="username"
                            v-model="username"
                            placeholder=""
                          />
                        </div>
                      </div>
                    <label>Password</label>
                    <div class="form-group">
                        <div>
                          <input
                            id="password"
                            type="password"
                            class="form-control"
                            name="password"
                            v-model="password"
                            placeholder="⦿⦿⦿⦿⦿⦿⦿⦿⦿⦿"
                          />
                        </div>
                      </div>
                    <div class="text-center">
                      <soft-button
                        class="my-4 mb-2"
                        variant="gradient"
                        color="primary"
                        full-width
                        @click="login"
                        >Sign in     
                      </soft-button>
                    </div>
                  </form>
                </div>
                <div style="width: 80px;margin-left: auto;margin-right: auto;" class="px-1 pt-0 text-center card-footer px-lg-2">
                  <!-- <p class="mx-auto mb-4 text-sm">
                    Don't have an account?
                    <router-link
                      :to="{ name: 'Sign Up' }"
                      class="text-primary text-gradient font-weight-bold"
                      >Sign up</router-link
                    >
                  </p> -->
                  <spinner v-if="isLoading"></spinner>
                </div>
              </div>
            </div>
            <!-- <div class="col-md-6">
              <div
                class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8"
              >
                <div
                  class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                  :style="{
                    backgroundImage:
                      'url(' +
                      require('@/assets/img/curved-images/curved9.jpg') +
                      ')',
                  }"
                ></div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import axios from "axios"
// import Navbar from "@/examples/PageLayout/Navbar.vue";
// import AppFooter from "@/examples/Footer.vue";
// import SoftInput from "@/components/SoftInput.vue";
// import SoftSwitch from "@/components/SoftSwitch.vue";
import logo from "@/assets/img/pulse.png";
import Spinner from "@/components/Icon/Spinner.vue"
import SoftAlert from "@/components/SoftAlert.vue";
import SoftButton from "@/components/SoftButton.vue";
const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";
import store from "@/store";

export default {
  name: "SignIn",
  components: {
    // Navbar,
    // SoftSwitch,
    // SoftInput,
    // AppFooter,
    Spinner,
    SoftAlert,
    SoftButton,
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig","saveCurrentUser","changeAuthenticationStatus"]),
    async login(e){
      e.preventDefault();
      this.isLoading = true
      const payload = {
        password:this.password,
        username:this.username
      }
      try{
        const response = await axios.post("/intranet/auth/login/",payload,{timeout: 5000})
        this.isLoading=false
        if(response.status==200){
          this.loginSuccess = true
          setTimeout(()=>{
            this.loginSuccess = false
            this.saveCurrentUser(response.data);
            this.changeAuthenticationStatus(true)
            if(!isNaN(parseInt(store.state.redirect_url))){
              this.$router.push("/read");
            }else{
              this.$router.push("/dashboard");
            }

          },2000)
        }
      }catch(e){
        this.isLoading=false
        if(Object.keys(e).includes("response") && e.response.status==401){
            this.loginFailure=true
            setTimeout(()=>{
              this.loginFailure = false
            },3000)
         }else{
          this.timeoutOrError = true
          setTimeout(()=>{
            this.timeoutOrError = false
          },4000)
         }
    }
    }
  },
  data:()=>{
    return {
      logo,
      username:"",
      password:"",
      loginSuccess:false,
      loginFailure:false,
      timeoutOrError:false,
      isLoading:false,
    }
  }
};
</script>
