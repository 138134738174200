<template>
    <div>
      <div class="container border-bottom">
          <div class="col-lg-12 row justify-space-between py-2 container border-bottom">
            <div class="col-lg-8 me-auto">
              <h3 class="lead text-dark pt-1 bold mb-1">
                {{ policy.policyName }}
              </h3>
            </div>
            <div class="row">
              {{ policy.policyDescription || "" }}
            </div>
          </div>
        <div>
          <web-viewer
            v-if="policy.policyAttachment && blobContent"
            :initialDoc="getUrlPath()"
            :docId="policy.id"
            :data="blobContent"
            :username="currentUser().username"
            :extension="blobMimeExt"
          />     
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  import { mapActions, mapGetters } from "vuex";
  import axios from "axios";
  import {formatDate} from "../utils/index"
  import WebViewer from "./components/WebViewer.vue";
  export default {
    name: "Read",
    components:{
      WebViewer
    },
      created() {
       this.policy = this.getCurrentPolicy();
       this.getDocumentBlob(this.policy.id);
     },
  
    methods: {
      ...mapGetters(["getCurrentPolicy","getBaseUrl","currentUser","getPostLogs"]),
      ...mapActions(["fetchPostAdditionalAttachmentsAction"]),
      formatDate,
      getUrlPath(){
        return ""
      },
        base64ToBlob(base64,mimeType) {
            const binaryString = window.atob(base64);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; ++i) {
              bytes[i] = binaryString.charCodeAt(i);
            }
  
        return new Blob([bytes], { type: mimeType });
      },
        async getDocumentBlob(policy_id){
        const accessToken = this.$store.getters.currentUser.access
        const res = await axios.get(`/intranet/posts/fetch_policies_blob/?policy_id=${policy_id}`,{ headers:{
                Authorization:`Bearer ${accessToken}`
        } });
        const {data} = res
        const mimeType = data.mimeType
        const blobRes =this.base64ToBlob(data.documentBase,mimeType);
        console.log({blobRes,ext:data.extension})
        this.blobContent = blobRes;
        this.blobMimeExt = data.extension;
        },   
      goBack(){
        this.$router.go(-1);
      },
    },
    data:()=>{
      return {
        policy:{},
        circularPostLogs:[],
        postAttachments:[],
        comment_text:"",
        postComments:[],
        blobContent:null,
        blobMimeExt:null,
      }
    }
    
  };
  </script>
  